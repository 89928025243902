// Had to add parsley to the JS files seperately.
// Because they were called like this "form.parsley().validate()" and this fails with parsley is not a function.
import "parsleyjs/dist/parsley.min.js";
import { toggleButtons, toggleSpinner, failed } from "./companyForm";
import { encode, decode } from "js-base64";

function appendChathistoryToDataForm(chathistoryEl, whitepaperClass, latestNewsClass, dataForm) {
    if (chathistoryEl && chathistoryEl.length) {
        let chathistory = [];
        let chatHistoryMessages = chathistoryEl.find("li.gen-ai-block--chatitem");
        if (chatHistoryMessages && chatHistoryMessages.length) {
            const lastHistoryMessageLength = chatHistoryMessages.length - 1;
            // Ignore last / latest message because it is from the user and already in the usermessage field.
            // Remove from content the visually hidden span content from the beginning of text.
            // Remove botprompt, white paper and latest news from chat history when sending to API to save on tokens.
            for (let i = 0; i < chatHistoryMessages.length; i++) {
                const historyMessage = chatHistoryMessages[i];
                if (historyMessage) {
                    const isBotPrompt = historyMessage.children.item(0).classList.contains("gen-ai-block--botprompt");
                    const isWhitePaper = historyMessage.classList.contains(whitepaperClass);
                    const isLatestNews = historyMessage.classList.contains(latestNewsClass);
                    const isBotPromptQuestionMessage = historyMessage.classList.contains("gen-ai-block--questionmessage");

                    if (i < lastHistoryMessageLength && !isBotPromptQuestionMessage && historyMessage.textContent) {
                        var messageContent = historyMessage.textContent
                            .replace("User says:", "")
                            .replace("Bot says:", "")
                            .replace("\n", "")
                            .trim();

                        if (isWhitePaper) {
                            messageContent = "Whitepaper was generated";
                        } else if (isLatestNews) {
                            messageContent = "Latest news was generated";
                        }

                        chathistory.push({
                            role: historyMessage.classList.contains("gen-ai-block--usermessage") ? "user" : "assistant",
                            content: messageContent,
                            isBotPrompt: isBotPrompt,
                            isWhitePaper: isWhitePaper,
                            isLatestNews: isLatestNews
                        });
                    }
                }
            }
        }
        dataForm.append("Chathistory", JSON.stringify(chathistory));
    }
}

function appendUserMessageToChatHistoryAndSession(chatmessageEl, chathistoryEl, formId) {
    if (chatmessageEl && chatmessageEl.length) {
        const chatMessageValue = chatmessageEl.val();

        if (chatMessageValue && chathistoryEl && chathistoryEl.length) {
            let userMessageAppend = `<li class="gen-ai-block--usermessage text--t2 gen-ai-block--chatitem"><span class="visually-hidden">User says:</span>${chatMessageValue}</li>`;
            chathistoryEl.append(userMessageAppend);
            setGenAiFormSession(formId, userMessageAppend);
        }
    }
}

function toggleBotPromptButtons(enable, formid) {
    if (formid) {
        let botPromptButtons = $(`.gen-ai-block--botprompt[data-formid="${formid}"]`);
        if (botPromptButtons && botPromptButtons.length > 0) {
            botPromptButtons.each(function () {
                let $button = $(this);
                if (enable) {
                    $button.prop('disabled', false);
                    $button.removeClass('disabled');
                }
                else {
                    $button.prop('disabled', true);
                    $button.addClass('disabled');
                }
            })
        }
    }
}

export function scrollToBottomOfChatHistory(chathistoryEl) {
    if (!chathistoryEl) {
        return;
    }

    let $chathistory = $(chathistoryEl);

    if ($chathistory.length > 0) {
        $chathistory.get(0).scrollTop = $chathistory.get(0).scrollHeight;
    }
}

// Used in onclick event in genaichat fullscreen button.
export function sendGenAiFullscreen(e, el) {
    e.preventDefault();
    e.stopPropagation();
    let sendfullscreenBtnEl = $(el);

    if (sendfullscreenBtnEl.length) {
        let chathistoryEl = $('#' + sendfullscreenBtnEl.attr("data-chathistoryid"));

        if (chathistoryEl.length) {
            chathistoryEl.toggleClass('gen-ai-block--chathistory-fullscreen');
        }
    }
}

export function addWhitePaperToClipboard(el) {
    const whitepaperBtn = el;
    const mdcontent = whitepaperBtn ? whitepaperBtn.children[0] : null;
    const mdContentValue = mdcontent && mdcontent.value ? mdcontent.value : null;

    function copyClipBoardOldWay() {
        if (mdContentValue) {
            let sampleTextarea = document.createElement("textarea");
            document.body.appendChild(sampleTextarea);
            sampleTextarea.value = mdContentValue;
            // Make sure we are only copying the textarea content in the page and nothing else.
            document.execCommand("copy", false, sampleTextarea.select());
            document.body.removeChild(sampleTextarea);
            alert("The whitepaper is copied.");
        }
    }

    if (mdcontent) {
        // Check to see if modern clipboard API is available. If not use the old one.
        if (navigator && navigator.clipboard && mdContentValue) {
            navigator.clipboard.writeText(mdContentValue).then(function() {
                alert("The whitepaper is copied.");
            }).catch(function(err) {
                console.error("Error copying whitepaper using clipboard API. Trying old way.", err);
                copyClipBoardOldWay();
            });
        } else {
            copyClipBoardOldWay();
        }
    }
}

// Used in onclick event in genaichat for form submit.
export function sendGenAiForm(e, el) {
    e.preventDefault();
    e.stopPropagation();
    const whitepaperClass = "gen-ai-block--whitepaper";
    const latestNewsClass = "gen-ai-block--latestnews";
    let sendmessageEl = $(el);
    const formId = sendmessageEl.length ? sendmessageEl.attr("data-formid") : null;
    let formEl = formId ? $('#' + formId) : null;
    const chatMesssageId = sendmessageEl.attr("data-chatmessageid");
    let chatmessageEl = $("#" + chatMesssageId);
    let chathistoryEl = $("#" + sendmessageEl.attr("data-chathistoryid"));
    let isFormValid = false;
    let parsleyInstance = formEl && formEl.length ? formEl.parsley({
        errorClass: '',
        successClass: ''
    }) : null;

    if (parsleyInstance) {
        parsleyInstance.validate();
        isFormValid = parsleyInstance.isValid();
    }

    if (isFormValid && sendmessageEl.length && formEl.length && chatmessageEl.val()) {
        let dataForm = new FormData(formEl[0]);
        appendUserMessageToChatHistoryAndSession(chatmessageEl, chathistoryEl, formId);
        appendChathistoryToDataForm(chathistoryEl, whitepaperClass, latestNewsClass, dataForm);
        $.ajax({
            url: formEl.attr("action"),
            type: 'POST',
            enctype: 'multipart/form-data',
            contentType: false,
            data: dataForm,
            processData: false,
            beforeSend: function () {
                // Show the spinner
                toggleSpinner(true, formEl);
                // Disable buttons
                toggleButtons(false, formEl);
                toggleBotPromptButtons(false, formId);
                scrollToBottomOfChatHistory(chathistoryEl);

                if (chatmessageEl.length) {
                    chatmessageEl.attr("disabled", "");
                }
            }
        }).done(function (result) {
            let validationMsg = formEl.find(".alert-danger-validation");
            if (validationMsg) {
                validationMsg.hide();
            }

            // Should we clear the message always instead of only when the API call is successful?
            if (chatmessageEl.length) {
                chatmessageEl.val("");
            }
            // If chat message is empty from backend eg. because of sanitizing validate the form again.
            if (result && !result.chatmessage) {
                parsleyInstance.validate();
                return;
            }

            if (result &&
                result.newMessages &&
                result.newMessages.length &&
                chathistoryEl.length) {
                for (let i = 0; i < result.newMessages.length; i++) {
                    const message = result.newMessages[i];
                    if (message && message.role === "assistant" && message.content) {
                        const messageIsWhitepaper = message.isWhitePaper;
                        let appendContent = `<li class="gen-ai-block--botmessage text--t2 gen-ai-block--chatitem${messageIsWhitepaper ? " " + whitepaperClass : ""}${message.isLatestNews ? " " + latestNewsClass : ""}"><span class="visually-hidden">Bot says:</span>${message.content}`;
                        if (messageIsWhitepaper && message.whitePaperMarkdown) {
                            appendContent += `<button type="button" class="gen-ai-block--aicopybutton" onclick="GenAIBlockScripts.addWhitePaperToClipboard(this)" aria-label="Copy whitepaper content in genai chat."><textarea class="d-none">${message.whitePaperMarkdown}</textarea><img src="/icons/chat-copy-icon.svg" height="20" width="20" alt=""></button>`;
                        }
                        appendContent += "</li>";
                        chathistoryEl.append(appendContent);
                        setGenAiFormSession(formId, appendContent);
                    }
                }
            }
        }).fail(function (result) {
            failed(formEl, result);
        }).always(function () {
            // Hide the spinner
            toggleSpinner(false, formEl);
            // Enable buttons
            toggleButtons(true, formEl);
            toggleBotPromptButtons(true, formId);
            scrollToBottomOfChatHistory(chathistoryEl);

            if (chatmessageEl.length) {
                chatmessageEl.removeAttr("disabled");
            }
        });
    } else if (!isFormValid) {
        let parsleyErrors = $('.parsley-errors-list.filled');
        if (parsleyErrors && parsleyErrors.length) {
            parsleyErrors.get(0).scrollIntoView({ behavior: 'auto', block: 'center', inline: 'start' });
        }
    }

    return false;
}

export function getGenAiFormSession(formId, enableDecodeBase64 = true) {
    try {
        if (formId) {
            let genAiFormData = sessionStorage.getItem(formId);

            if (enableDecodeBase64 && genAiFormData) {
                genAiFormData = decode(genAiFormData);
            }
            return genAiFormData;
        }
        console.error("[getGenAiFormSession] Error while trying to get data from session storage. Session id does not exist");
        return null;
    } catch (err) {
        console.error("[getGenAiFormSession] Error while trying to get data from session storage with following key: " +
            formId + " error: " + err);
        return null;
    }
}

export function setGenAiFormSession(formId, dataString) {
    try {
        if (!dataString) {
            dataString = "";
        }

        if (formId) {
            const genAiSessionData = getGenAiFormSession(formId, true);

            // Add old session data if it exists to avoid overwriting the existing data 
            if (genAiSessionData) {
                sessionStorage.setItem(formId, encode(genAiSessionData + dataString));
                return;
            } else {
                sessionStorage.setItem(formId, encode(dataString));
                return;
            }
        }

        console.error("[setGenAiFormSession] Error while trying to set data to session storage. Session id does not exist. Returning the content that was supposed to be put into storage.");
        return dataString;

    } catch (err) {
        console.error("[setGenAiFormSession] Error while trying to set data to session storage with following key: " +
            formId + " error: " + err +
            " Returning the content that was supposed to be put into storage without conversion.. Value: " + dataString);
        let formEl = $("#" + formId);
        let chatmessageEl = $("#" + formId).find(".gen-ai-block--chatmessage");
        // Hide the spinner
        toggleSpinner(false, formEl);
        // Enable buttons
        toggleButtons(true, formEl);
        // remove disabled attribute from chat message input
        if (chatmessageEl.length) {
            chatmessageEl.removeAttr("disabled");
        }
        return dataString;
    }
}

export function resetGenAiChatHistory(e) {
    e.preventDefault();
    e.stopPropagation();
    let resetchatHistoryBtnEl = $(this);

    if (resetchatHistoryBtnEl.length) {
        let formid = resetchatHistoryBtnEl.attr("data-formid");
        let chathistoryEl = $("#" + resetchatHistoryBtnEl.attr("data-chathistoryid"));

        // This way of declaring function in a function is called closure and ensures no one outside of this function can use it.
        // MDN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Closures
        function clearChatHistoryUI() {
            if (chathistoryEl.length) {
                // Remove everything from chat history except question message and botprompts.
                // Because they come from server only one time when initializing the block.
                // This also makes sure we dont have to reinitialize the JS for bot prompts.
                chathistoryEl.contents().filter(function () {
                    // Check if element has class name. If it does it should also have class list.
                    if (this.className) {
                        return !this.classList.contains("gen-ai-block--questionmessage") &&
                            !this.classList.contains("gen-ai-block--botpromptitem");
                    }
                }).remove();
            }
        }

        function closeResetChatModal() {
            var modalEl = $('#' + resetchatHistoryBtnEl.attr("data-modalid"));
            var cancelButtonEl = modalEl ? modalEl.first(('.gen-ai-block--airesetcancel')) ||
                modalEl.first(('.gen-ai-block--airesetclose')) : null;

            if (cancelButtonEl && cancelButtonEl.length) {
                cancelButtonEl.trigger("click");
            }
        }

        if (formid && chathistoryEl.length && getGenAiFormSession(formid)) {
            try {
                sessionStorage.removeItem(formid);
                clearChatHistoryUI();
            } catch (err) {
                console.error("[getGenAiFormSession] Error while trying to remove data from session storage with following key: " +
                    formid + " error: " + err);
                clearChatHistoryUI();
            }
        }
        // Always close the modal when clicking confirm.
        closeResetChatModal();
    }
}

export function handleBotPromptClick(e) {
    e.preventDefault();
    e.stopPropagation();
    let botpromptEl = $(this);

    if (botpromptEl.length) {
        let chatmessageEl = $("#" + botpromptEl.attr("data-chatmessageid"));
        let submitFormBtnEl = $("#" + botpromptEl.attr("data-formsubmitbtnid"));
        let buttonText = botpromptEl.text();

        if (chatmessageEl.length && submitFormBtnEl.length && buttonText) {
            chatmessageEl.val(buttonText);
            submitFormBtnEl.trigger("click");
        }
    }
}

$(function () {
    if (!$('.gen-ai-block').length) {
        return;
    }

    $('.gen-ai-block--botprompt').on('click', handleBotPromptClick);
    $('.gen-ai-block--airesetconfirm').on('click', resetGenAiChatHistory);
});
